#presentation table tr, #presentation table td, #presentation table th {
	padding: 5px;
}

.Text p[id*="90"] {
  transform: rotate(90deg);
}

.Text p[id*="270"] {
  transform: rotate(270deg);
}

.Text a, .Text a:focus, .Text a:visited, .Text a:active {
  color: #000;
  text-decoration: underline;
}
