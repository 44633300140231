#root, .App {
  position: relative;
  min-height: 100vh;
  background-color: #F9FAFB;
}

.container, .menu-container {
  max-width: 1440px;
}

body {
  font-family: "Inter Variable", sans-serif;
}

footer {
  padding-top: 100px;
}

footer #footer {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  height: 2.5rem;
}

/* links */
a, a:focus, a:visited, a:active {
  text-decoration: none;
}

a {
  color: inherit;
}

.js-plotly-plot .plotly .nsewdrag {
  cursor: pointer;
}
